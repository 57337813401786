.tableValue {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #101928;
}

.tableLink {
  color: #000;
}

.tableLink:hover {
  color: #1677FF;
}

.tableHeader {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #8591A3;
}
