.Tag {
  padding: 3px 8px 3px 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  border-radius: 6px;
}

.TagCustomProcessing {
  color: #4870FE!important;
  background: #EDF1FF;
  border-color: #A3B7FF!important;
}

.TagCustomSuccess {
  color: #52C41A!important;
  background: #F6FFED;
  border-color: #B7EB8F!important;
}

.TagCustomWarning {
  color: #FAAD14!important;
  background: #FFFBE6;
  border-color: #FFE58F!important;
}

.TagCustomError {
  color: #FF4D4F!important;
  background: #FFF2F0;
  border-color: #FFCCC7!important;
}

.TagCustomDraft {
  color: #67768C!important;
  background: #F7F8F9;
  border-color: #D1D6DD!important;
}

.TagCustomSkills {
  color: #67768C!important;
  background: #F7F8F9;
  border-color: #D1D6DD!important;
}
