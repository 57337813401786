.listItemLabel {
  display: inline-block;
  width: 100%;
  max-width: 275px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #67768C;
}
