.buttonPrimary {
  font-size: inherit;
  color: #FFF;
  background-color: #3438E0 !important;
}

.buttonPrimary:hover {
  color: #FFF !important;
  background-color: #3438E0 !important;
}

.buttonPrimaryGhost {
  font-size: inherit;
  color: #3438E0 !important;
  border-color: #FFF !important;
  background-color: rgba(72, 112, 254, 0.10) !important;
}

.buttonPrimaryGhost:hover {
  font-size: inherit;
  color: #3438E0 !important;
  border-color: #FFF !important;
  background-color: rgba(72, 112, 254, 0.20) !important;
}

.buttonSecondary {
  font-size: inherit;
  color: #3438E0;
  border-color: #3438E0;
  background-color: #FFF !important;
}

.buttonSecondary:hover {
  color: #4870FE !important;
  border-color: #4870FE;
  background-color: #FFF !important;
}

.buttonSecondaryGhost {
  font-size: inherit;
  color: #000 !important;
  border-color: #D1D6DD !important;
  background-color: #FFF !important;
}

.buttonSecondaryGhost:hover {
  color: #000 !important;
  border-color: #D1D6DD !important;
  background-color: #FFF !important;
}
