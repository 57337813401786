.buttonReturn {
  padding: 0;
  margin: 0 -4px 0 -5px;
  font-weight: 500;
  color: #3438E0;
}

.buttonReturn svg {
  width: 24px;
  height: 24px;
}

.buttonReturn svg g path {
  fill: #3438E0;
  transition: fill .2s ease-in-out;
}

.buttonReturn:hover svg g path {
  fill: #69b1ff;
}
