@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "HelveticaNeueCyr";
  src: url("./assets/fonts/HelveticaNeueCyr-Medium.eot");
  src: local("HelveticaNeueCyr-Medium"),
  url("./assets/fonts/HelveticaNeueCyr-Medium.eot?#iefix") format("embedded-opentype"),
  url("./assets/fonts/HelveticaNeueCyr-Medium.woff2") format("woff2"),
  url("./assets/fonts/HelveticaNeueCyr-Medium.woff") format("woff"),
  url("./assets/fonts/HelveticaNeueCyr-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaNeueCyr";
  src: url("./assets/fonts/HelveticaNeueCyr-MediumItalic.eot");
  src: local("HelveticaNeueCyr-MediumItalic"),
  url("./assets/fonts/HelveticaNeueCyr-MediumItalic.eot?#iefix") format("embedded-opentype"),
  url("./assets/fonts/HelveticaNeueCyr-MediumItalic.woff2") format("woff2"),
  url("./assets/fonts/HelveticaNeueCyr-MediumItalic.woff") format("woff"),
  url("./assets/fonts/HelveticaNeueCyr-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "HelveticaNeueCyr";
  src: url("./assets/fonts/HelveticaNeueCyr-Italic.eot");
  src: local("HelveticaNeueCyr-Italic"),
  url("./assets/fonts/HelveticaNeueCyr-Italic.eot?#iefix") format("embedded-opentype"),
  url("./assets/fonts/HelveticaNeueCyr-Italic.woff2") format("woff2"),
  url("./assets/fonts/HelveticaNeueCyr-Italic.woff") format("woff"),
  url("./assets/fonts/HelveticaNeueCyr-Italic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "HelveticaNeueCyr";
  src: url("./assets/fonts/HelveticaNeueCyr-Roman.eot");
  src: local("HelveticaNeueCyr-Roman"),
  url("./assets/fonts/HelveticaNeueCyr-Roman.eot?#iefix") format("embedded-opentype"),
  url("./assets/fonts/HelveticaNeueCyr-Roman.woff2") format("woff2"),
  url("./assets/fonts/HelveticaNeueCyr-Roman.woff") format("woff"),
  url("./assets/fonts/HelveticaNeueCyr-Roman.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "HelveticaNeueCyr", Roboto, sans-serif;
  background-color: #F3F4F6;
  color: #101928;
}

.header {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  color: #101928;
}

.sub-header {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #101928;
}

.table-row-head {
  display: block;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  color: #67768C;
}

.btn-text {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
}
