.defaultTag {
  padding: 5px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  border-radius: 24px;
  border: 1px solid #D1D6DD;
  background-color: #F7F8F9;
}

.tableTag {
  margin-right: 10px  ;
  padding: 2px 6px 2px 6px;
  border-radius: 6px;
  background: #F0F1F3;
  color: #101928;
}
